import Translations from "@recare/translations/types";

const TRANSITIONAL_CARE_KEY = "transitional_care" as const;

const TRANSITIONAL_CARE_STEPS = {
  IMPORT_DATA: "IMPORT_DATA",
  SAVE_CREATE_PDF: "SAVE_CREATE_PDF",
} as const;

export const TRANSITIONAL_CARE = {
  key: TRANSITIONAL_CARE_KEY,
  steps: {
    [TRANSITIONAL_CARE_STEPS.IMPORT_DATA]: {
      key: TRANSITIONAL_CARE_STEPS.IMPORT_DATA,
      title: (t: Translations) => t.tours.transitionalCare.stepA.title,
      intro: (t: Translations) => t.tours.transitionalCare.stepA.intro,
      step: 1,
    },
    [TRANSITIONAL_CARE_STEPS.SAVE_CREATE_PDF]: {
      key: TRANSITIONAL_CARE_STEPS.SAVE_CREATE_PDF,
      title: (t: Translations) => t.tours.transitionalCare.stepB.title,
      intro: (t: Translations) => t.tours.transitionalCare.stepB.intro,
      step: 2,
    },
  },
  nextLabel: (t: Translations) => t.tours.reverseSearch.nextButton,
  prevLabel: (t: Translations) => t.tours.reverseSearch.backButton,
  doneLabel: (t: Translations) => t.tours.reverseSearch.doneButton,
  disablePageScroll: true,
} as const;
