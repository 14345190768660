import {
  REQUEST_ACTION_ACCEPT_REQUEST,
  REQUEST_ACTION_ACCESS_PATIENT_DATA,
  REQUEST_ACTION_ACCESS_TO_DATA,
  REQUEST_ACTION_DECLINE_REQUEST,
  REQUEST_ACTION_NEXT_REQUEST,
  REQUEST_ACTION_PATIENT_PREFERRED,
  REQUEST_ACTION_REJECT_PROVIDER,
  REQUEST_ACTION_SEND_FILE,
  REQUEST_ACTION_SEND_MESSAGE,
  REQUEST_ACTION_SEND_REQUEST_TO_SUGGESTED_PROVIDER,
  REQUEST_ACTION_SET_RECEIVER_ASSIGNEE,
  REQUEST_ACTION_VALIDATE_PROVIDER,
  UNDO_REQUEST_ACTION_ACCEPT_REQUEST,
  UNDO_REQUEST_ACTION_MANUALLY_ARCHIVED,
  UNDO_REQUEST_ACTION_REJECT_PROVIDER,
  UNDO_REQUEST_ACTION_REQUEST_DECLINATION,
  UNDO_REQUEST_ACTION_SUGGEST_PROVIDER,
} from "@recare/core/consts";
import { RequestActionType, UndoActionType } from "@recare/core/types";
import {
  auctionResponse,
  auctionValidation,
  requestActions,
} from "./fragments";

const blankFragment = `
id
`;

const rejectProviderActionFragment = `
id
status
validation {
  ${auctionValidation()}
}
${requestActions}`;

const sendSuggestionActionFragment = `
id
status
${requestActions}`;

const undoSendSuggestionActionFragment = blankFragment;

const acceptRequestActionFragment = `
id
status
validation {
  ${auctionValidation()}
}
response {
  ${auctionResponse()}
}
${requestActions}`;

const setAssigneeFragment = `
  id
  assignee {
    id
    first_name
    last_name
  }
`;

const declineRequestActionFragment = `
id
status
response {
  ${auctionResponse()}
}
careprovider {
  id
  profile {
    capacity {
      has_capacity
      available_date
    }
  }
}
${requestActions}`;

const undoDeclineRequestActionFragment = `
id
status
response {
  ${auctionResponse()}
}
${requestActions}`;

const nextRequestFragment = blankFragment;

const setPatientPreferredActionFragment = `
id
patient_preferred
patient_preferred_reason
`;

const requestActionFragments: {
  [requestAction in RequestActionType | UndoActionType]: string;
} = {
  [REQUEST_ACTION_ACCEPT_REQUEST]: acceptRequestActionFragment,
  [REQUEST_ACTION_ACCESS_TO_DATA]: blankFragment,
  [REQUEST_ACTION_ACCESS_PATIENT_DATA]: blankFragment,
  [REQUEST_ACTION_DECLINE_REQUEST]: declineRequestActionFragment,
  [REQUEST_ACTION_NEXT_REQUEST]: nextRequestFragment,
  [REQUEST_ACTION_REJECT_PROVIDER]: rejectProviderActionFragment,
  [REQUEST_ACTION_SEND_FILE]: blankFragment,
  [REQUEST_ACTION_SEND_MESSAGE]: blankFragment,
  [REQUEST_ACTION_SEND_REQUEST_TO_SUGGESTED_PROVIDER]:
    sendSuggestionActionFragment,
  [REQUEST_ACTION_SET_RECEIVER_ASSIGNEE]: setAssigneeFragment,
  [UNDO_REQUEST_ACTION_ACCEPT_REQUEST]: acceptRequestActionFragment,
  [UNDO_REQUEST_ACTION_MANUALLY_ARCHIVED]: blankFragment,
  [UNDO_REQUEST_ACTION_REJECT_PROVIDER]: rejectProviderActionFragment,
  [UNDO_REQUEST_ACTION_REQUEST_DECLINATION]: undoDeclineRequestActionFragment,
  [UNDO_REQUEST_ACTION_SUGGEST_PROVIDER]: undoSendSuggestionActionFragment,
  [REQUEST_ACTION_PATIENT_PREFERRED]: setPatientPreferredActionFragment,
  // handled as a search action
  [REQUEST_ACTION_VALIDATE_PROVIDER]: blankFragment,
};

export default requestActionFragments;
