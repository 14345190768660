import { ApolloError } from "@apollo/client";
import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "@recare/core/consts";
import {
  AnyObject,
  AuctionRequest,
  QueryProgress,
  WarningsPayload,
} from "@recare/core/types";
import { PatientEncryptionProvider } from "dsl/organisms/Encryption/Patient";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { compose, mapProps } from "recompose";
import { logError } from "../utils";

export function getQueryProgress(loading: boolean, error?: AnyObject) {
  if (loading) return QUERY_PROGRESS_PENDING;
  if (error) {
    logError(error);
    return QUERY_PROGRESS_FAILED;
  }
  return QUERY_PROGRESS_SUCCEED;
}

export const toHoc = (
  QueryComponent: React.ComponentType<any>,
  outProp: string,
) =>
  compose(
    (WrappedComponent: React.ComponentType<any>) =>
      function WrappedComp(props: AnyObject) {
        return (
          <QueryComponent {...props}>
            {(data: AnyObject, queryProgress: QueryProgress) => (
              <WrappedComponent
                dataApollo={data}
                queryProgressApollo={queryProgress}
                {...props}
              />
            )}
          </QueryComponent>
        );
      },
    mapProps(
      ({
        dataApollo,
        queryProgressApollo,
        ...props
      }: {
        dataApollo: AnyObject;
        queryProgress: QueryProgress;
        queryProgressApollo: QueryProgress;
      }) => {
        const newData: AnyObject = props;
        newData.queryProgress = queryProgressApollo;
        newData[outProp] = dataApollo;
        return newData;
      },
    ),
  );

export const useNoCacheData = () => {
  const loadingRef = useRef();
  const [response, setResponse] = useState<{
    careproviderWarnings?: WarningsPayload;
    requests?: AuctionRequest[];
  }>();
  const saveResponse = useCallback(
    (data: any, loading: any) => {
      const prevLoading = loadingRef.current;
      if (
        (prevLoading == true || prevLoading == undefined) &&
        loading == false
      ) {
        setResponse(data);
      }

      loadingRef.current = loading;
    },
    [response],
  );
  return { response, saveResponse };
};

// https://github.com/apollographql/react-apollo/issues/3361
const useFixLoading = () => {
  const refetchRef = useRef(() => {});
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (isLoading) {
      timeoutRef.current = setTimeout(() => {
        refetchRef.current();
      }, 10 * 1000);
    }

    return () => {
      if (timeoutRef.current != null) clearTimeout(timeoutRef.current);
    };
  }, [isLoading]);

  return (loading: boolean, refetch: AnyFunction) => {
    setIsLoading(loading);
    refetchRef.current = refetch;
  };
};

export function ApolloLoading({
  children,
  data,
  error,
  loading,
  refetch,
}: {
  children: (
    auctionRequest: AuctionRequest,
    queryProgress: QueryProgress,
  ) => ReactNode;
  data: AnyObject;
  error?: ApolloError;
  loading: boolean;
  refetch: AnyFunction;
}) {
  const fixLoading = useFixLoading();

  useEffect(() => {
    fixLoading(loading, refetch);
  }, [loading, refetch]);

  return (
    <PatientEncryptionProvider patient={data?.auctionRequest?.patient}>
      {children(data?.auctionRequest, getQueryProgress(loading, error))}
    </PatientEncryptionProvider>
  );
}
