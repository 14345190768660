import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
  TRACK_EVENTS,
} from "@recare/core/consts";
import { onPremHttpRequest } from "@recare/core/model/careseekers";
import { QueryProgress } from "@recare/core/types";
import { useCallback } from "react";
import { useTracking } from "react-tracking";

export default function useSSLImport({
  on_premise_authorization_token,
  on_premise_domain_ssl,
  setImportStatus,
}: {
  on_premise_authorization_token: string | undefined;
  on_premise_domain_ssl: string | undefined;
  setImportStatus: React.Dispatch<React.SetStateAction<QueryProgress>>;
}) {
  const { trackEvent } = useTracking();

  const trackError = (reason: string) => {
    trackEvent({
      name: TRACK_EVENTS.HL7_IMPORT_ERROR,
      reason,
      endpoint: on_premise_domain_ssl,
    });
  };

  const trackImport = (status: number) => {
    trackEvent({
      name: TRACK_EVENTS.HL7_PATIENT_IMPORT,
      status,
      endpoint: on_premise_domain_ssl,
    });
  };

  const callImport = useCallback(
    async (
      externalId: string | undefined,
      onChangeKisHelper: (value: any) => void,
    ) => {
      setImportStatus(QUERY_PROGRESS_PENDING);
      try {
        const response = await onPremHttpRequest({
          on_premise_domain_ssl,
          path: `patient/${externalId}`,
          method: "GET",
          on_premise_authorization_token,
        });

        if (response) {
          trackImport(response.status);
          if (response.status == 200) {
            const payload = await response.json();
            onChangeKisHelper(payload);
            setImportStatus(QUERY_PROGRESS_SUCCEED);
          } else {
            onChangeKisHelper({ status: response.status });
            trackError("not found");
            setImportStatus(QUERY_PROGRESS_FAILED);
          }
        }
      } catch (err) {
        trackImport(-1);
        trackError("failed to fetch");
        console.log("Kis SSL Import error", err);
        setImportStatus(QUERY_PROGRESS_FAILED);
      }
    },
    [on_premise_domain_ssl],
  );

  return callImport;
}
