import { useIsSealdOnly } from "@recare/app/src/containers/SealdOnlyProvider";
import { usePatientEncryption } from "@recare/app/src/dsl/atoms/Contexts";
import { usePrivateKey } from "@recare/app/src/reduxentities/selectors";
import {
  Account,
  ApolloEncryptionContext,
  EncryptedField,
  Patient,
} from "@recare/core/types";
import { useTracking } from "react-tracking";

export function getPatientSessionKey(
  account: Account | null | undefined,
  patient: Patient | null | undefined,
) {
  if (!account || !patient) return null;

  const { id } = account;
  const { session_key_context, session_keys } = patient;

  return session_key_context?.has_session_keys
    ? session_key_context?.session_key
    : session_keys?.find(({ account_id }) => account_id === id);
}

export function getEncryptedValue(v: EncryptedField | null | undefined) {
  if (!v) return null;
  return v.decrypted;
}

export function useApolloEncryptionContext(
  context: Partial<Omit<ApolloEncryptionContext, "privateKey">> = {},
): ApolloEncryptionContext {
  const privateKey = usePrivateKey();
  const { trackEvent } = useTracking();

  // Consider encryption is only enabled for patientType != CARE, so
  // this will be false when outside of the PatientContext scope
  // Thus allowing decrypt to be passed to the hook to override for the dashboards for ex.
  const { encryptionAvailable } = usePatientEncryption();
  const { isSealdOnly } = useIsSealdOnly();

  return {
    decrypt: encryptionAvailable,
    privateKey,
    trackEvent,
    isSealdOnly,
    ...context,
  };
}

export const NO_ENCRYPTION_DENIED = "denied";
export const NO_ENCRYPTION_CARESEEKER_CONFIG = "missing_careseeker_config";
