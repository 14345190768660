import Translations from "@recare/translations/types";

export const SEARCH_MERGE_AUCTION_STARTED_KEY = "search_merge_started" as const;

const SEARCH_MERGE_AUCTION_STARTED_STEPS = {
  CONTACTED_TABLE: "contacted_table",
  PATIENT_WISH: "patient_wish",
  STATUS: "status",
} as const;

export const SEARCH_MERGE_AUCTION_STARTED = {
  key: SEARCH_MERGE_AUCTION_STARTED_KEY,
  steps: {
    [SEARCH_MERGE_AUCTION_STARTED_STEPS.CONTACTED_TABLE]: {
      key: SEARCH_MERGE_AUCTION_STARTED_STEPS.CONTACTED_TABLE,
      intro: (t: Translations) => t.tours.searchMergeContacted.stepA.intro,
      title: (t: Translations) => t.tours.searchMergeContacted.stepA.title,
      step: 1,
    },
    [SEARCH_MERGE_AUCTION_STARTED_STEPS.PATIENT_WISH]: {
      key: SEARCH_MERGE_AUCTION_STARTED_STEPS.PATIENT_WISH,
      intro: (t: Translations) => t.tours.searchMergeContacted.stepB.intro,
      title: (t: Translations) => t.tours.searchMergeContacted.stepB.title,
      step: 2,
    },
    [SEARCH_MERGE_AUCTION_STARTED_STEPS.STATUS]: {
      key: SEARCH_MERGE_AUCTION_STARTED_STEPS.STATUS,
      intro: (t: Translations) => t.tours.searchMergeContacted.stepC.intro,
      title: (t: Translations) => t.tours.searchMergeContacted.stepC.title,
      step: 3,
    },
  },
  nextLabel: (t: Translations) => t.tours.reverseSearch.nextButton,
  prevLabel: (t: Translations) => t.tours.reverseSearch.backButton,
  doneLabel: (t: Translations) => t.tours.reverseSearch.doneButton,
  exitOnOverlayClick: false,
  disablePageScroll: true,
} as const;
