import Typography from "@mui/material/Typography";
import { WHITE } from "ds/materials/colors";
import { FONT_SIZE_14 } from "ds/materials/typography";
import { PlaceholderProps } from "react-select";
import styled from "styled-components";
import { SelectOption } from "./types";

const SelectFieldPlaceholder = styled.div`
  font-size: ${FONT_SIZE_14};
`;

export function Placeholder({
  children,
  innerProps,
  selectProps: { hasError, light },
}: PlaceholderProps<SelectOption>) {
  return (
    <SelectFieldPlaceholder>
      <Typography
        color={light ? undefined : hasError ? "error" : "textSecondary"}
        style={{ fontSize: FONT_SIZE_14, color: light ? WHITE : undefined }}
        {...(innerProps as any)}
      >
        {children}
      </Typography>
    </SelectFieldPlaceholder>
  );
}
