import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberSharpIcon from "@mui/icons-material/WarningAmberSharp";
import { Button, IconButton, SxProps, Theme } from "@mui/material";
import Alert, { AlertProps } from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import { ClassNameMap } from "@mui/styles/withStyles";
import { BannerAction, TourElement } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import { HorizontalLayout } from "ds/materials/layouts";
import { dp } from "ds/materials/metrics";
import { useFocusElement } from "dsl/hooks/useFocusElement";
import { CSSProperties, useState } from "react";
import { useStyles } from "./styles";

const ICON_MAPPING = {
  success: <CheckCircleOutlineSharpIcon fontSize="inherit" />,
  error: <WarningAmberSharpIcon fontSize="inherit" />,
  warning: <InfoOutlinedIcon fontSize="inherit" />,
  info: <InfoOutlinedIcon fontSize="inherit" />,
};

export type InfoBannerProps = {
  actions?: BannerAction[];
  header?: React.ReactNode | string;
  highContrast?: boolean;
  icon?: AlertProps["icon"];
  message: React.ReactNode | string;
  messageMaxWidth?: CSSProperties["maxWidth"];
  messageTour?: TourElement;
  noIcon?: boolean;
  onClose?: AlertProps["onClose"];
  preferred?: boolean;
  severity: AlertProps["severity"];
  shouldFocus?: boolean;
  testId?: string;
  variant?: AlertProps["variant"];
  withClose?: boolean;
  wrapperStyle?: SxProps<Theme>;
};

const InfoBannerCloseIcon = ({
  actions,
  classes,
  onClose,
  setOpen,
  shouldFocus = true,
  withClose,
}: Pick<
  InfoBannerProps,
  "shouldFocus" | "onClose" | "withClose" | "actions"
> & {
  classes: ClassNameMap<"closeIcon">;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const firstFocusableElement = useFocusElement<HTMLButtonElement>({
    dependencies: [withClose],
    shouldFocus: !!withClose && !actions?.length && shouldFocus,
  });
  const translations = useTranslations();

  if (!withClose && !onClose) return null;

  return (
    <IconButton
      aria-label={translations.actions.close}
      className={classes.closeIcon}
      data-testid="notification-close"
      onClick={(e) => {
        setOpen(false);
        onClose?.(e);
      }}
      ref={firstFocusableElement}
      size="small"
    >
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );
};

const InfoBannerAdditionalActions = ({
  actions,
  classes,
  shouldFocus = true,
  withClose,
}: Pick<InfoBannerProps, "shouldFocus" | "withClose"> & {
  actions: InfoBannerProps["actions"];
  classes: ClassNameMap<"actionButton" | "verticalDivider">;
}) => {
  const firstFocusableElement = useFocusElement<HTMLButtonElement>({
    dependencies: [withClose],
    shouldFocus: !!actions && shouldFocus,
  });

  if (!actions) return null;

  return (
    <>
      <div className={classes.verticalDivider} />
      {actions.map(({ dataTestId, label, onClick, tour }, index) => (
        <span key={label} {...tour}>
          <Button
            className={classes.actionButton}
            data-testid={dataTestId}
            onClick={onClick}
            ref={index === 0 ? firstFocusableElement : undefined}
            variant="text"
          >
            {label}
          </Button>
        </span>
      ))}
    </>
  );
};

const InfoBannerActions = ({
  actions,
  classes,
  onClose,
  setOpen,
  shouldFocus = true,
  withClose,
}: Pick<
  InfoBannerProps,
  "actions" | "shouldFocus" | "onClose" | "withClose"
> & {
  classes: ClassNameMap<"actionButton" | "closeIcon" | "verticalDivider">;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <HorizontalLayout
      style={{ height: "100%", width: "100%" }}
      aligned
      overflow="visible"
    >
      <InfoBannerAdditionalActions
        actions={actions}
        classes={classes}
        shouldFocus={shouldFocus}
      />
      <InfoBannerCloseIcon
        actions={actions}
        classes={classes}
        onClose={onClose}
        setOpen={setOpen}
        shouldFocus={shouldFocus}
        withClose={withClose}
      />
    </HorizontalLayout>
  );
};

export function InfoBanner({
  actions,
  wrapperStyle = { width: "100%" },
  header,
  message,
  variant = "standard",
  icon,
  severity = "info",
  withClose,
  noIcon,
  onClose,
  testId,
  messageMaxWidth,
  messageTour,
  highContrast,
  shouldFocus = true,
}: InfoBannerProps) {
  const [open, setOpen] = useState(true);
  const classes = useStyles({
    severity,
    variant,
    noIcon,
    messageMaxWidth,
    highContrast,
  })();

  const Container = typeof message === "string" ? "p" : "div";

  return (
    <Collapse sx={wrapperStyle} in={open}>
      <Alert
        action={
          <InfoBannerActions
            classes={classes}
            actions={actions}
            onClose={onClose}
            setOpen={setOpen}
            shouldFocus={shouldFocus}
            withClose={withClose}
          />
        }
        aria-live="polite"
        classes={classes}
        data-testid={testId}
        icon={icon || ICON_MAPPING[severity]}
        role="status"
        severity={severity}
        sx={{
          overflowWrap: "break-word",
          "& .Mui-focusVisible": {
            outlineOffset: dp(-2),
          },
        }}
        variant={variant}
      >
        {header && <AlertTitle>{header}</AlertTitle>}
        <Container {...messageTour} style={{ margin: 0, padding: 0 }}>
          {message}
        </Container>
      </Alert>
    </Collapse>
  );
}
