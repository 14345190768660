import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Translations from "@recare/translations/types";
import RSButton from "ds/components/RSButton";

export default function ReimportModal({
  onCancel,
  onImport,
  open,
  translations,
}: {
  onCancel: () => void;
  onImport: () => void;
  open: boolean;
  translations: Translations;
}) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        {translations.patient.kis.headlineReimportPatientData}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translations.patient.kis.reimportPatientDataHint}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <RSButton
          color="primary"
          id="cancel"
          loading="na"
          onClick={onCancel}
          variant="text"
        >
          {translations.actions.cancel}
        </RSButton>
        <RSButton
          color="primary"
          id="import"
          inverted
          loading="na"
          onClick={onImport}
          variant="text"
        >
          {translations.actions.import}
        </RSButton>
      </DialogActions>
    </Dialog>
  );
}
