import { useTheme } from "@mui/material/styles";
import {
  FormElement,
  FormElementProps,
  isValid,
} from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import { iconHoverBorderStyle } from "ds/materials/layouts";
import { dp } from "ds/materials/metrics";
import { useEffect, useMemo, useState } from "react";
import Select, { PropsValue } from "react-select";
import { Control } from "./Control";
import { DropdownIndicator } from "./DropdownIndicator";
import { Menu } from "./Menu";
import { MultiValue as MultiValueComp } from "./MultiValue";
import { NoOptionsMessage } from "./NoOptionsMessage";
import { Option } from "./Option";
import { Placeholder } from "./Placeholder";
import { SingleValue as SingleValueComp } from "./SingleValue";
import { ValueContainer } from "./ValueContainer";
import { SelectOption, SelectType } from "./types";
import {
  getTextFieldProps,
  getTextFieldStyles,
  isValueEmpty,
  useStyles,
} from "./utils";

function ReactSelect({
  ariaLabel,
  cannotClear = false,
  closeMenuOnSelect = true,
  customCss,
  customNoResult,
  disabled,
  disableUnderline = false,
  elementName,
  errorOverride,
  floatingLabel = false,
  fontSize,
  hideSelectedOptions = true,
  id,
  isLoading,
  isSearchable = true,
  label,
  light,
  maxWidth,
  minWidth,
  multiple = false,
  onBlur,
  onChange = (v: any) => v,
  onFocus,
  onMouseOut,
  onMouseOver,
  onSearch = (e: any) => e,
  options,
  placeholder = "",
  presentationChips,
  required,
  testId,
  useShortValue = false,
  validation,
  value = null,
  width = dp(250),
  withChipEllipsis,
  withLightDecorators,
  withPortal = false,
}: SelectType) {
  const [localValue, setLocalValue] = useState<
    PropsValue<SelectOption> | undefined
  >(value);
  const theme = useTheme();
  const classes = useStyles({
    fontSize,
    isSearchable,
    withChipEllipsis,
    withLightDecorators,
  })();
  const translations = useTranslations();
  const [isShrink, setIsShrink] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  const empty = floatingLabel && isValueEmpty(value, multiple);
  const onlyOptionIsSelected =
    options?.length === 1 && options[0]?.id === value?.id;
  const hasError = errorOverride || isValid(validation) === false;
  const textFieldStyles = getTextFieldStyles({
    isShrink,
    hasError,
  });

  const selectPlaceholder = useMemo((): string => {
    if (!floatingLabel) {
      return placeholder !== ""
        ? placeholder
        : translations.actions.selectPlaceholder;
    }
    return (label ? isShrink : true) && placeholder !== "" ? placeholder : "";
  }, [label, isShrink, floatingLabel]);

  const textFieldProps = getTextFieldProps({
    customNoResult,
    elementName,
    empty,
    floatingLabel,
    isShrink,
    label,
    onBlur,
    onFocus,
    onSearch,
    placeholder,
    required,
    setIsShrink,
    setSearchInputValue,
    testId,
    textFieldStyles,
  });

  useEffect(() => {
    if (!empty) setIsShrink(true);
  }, [empty]);

  useEffect(() => {
    let newValue = value;

    if (multiple && Array.isArray(value)) {
      setLocalValue(value);
    } else if (value && options.length) {
      newValue = options.find(
        (el) => el.label === value.label || el.value === value,
      );
      setLocalValue(newValue);
      // in algolia there are no "options" on convertIn
    } else if (value && !!value.label && !!value.value) {
      setLocalValue(newValue);
    }
  }, [value]);

  return (
    <div
      data-testid={`select-wrapper-${elementName}`}
      className={classes?.root}
      style={{
        maxWidth: maxWidth ?? "100%",
        minWidth,
      }}
      onMouseEnter={onMouseOver}
      onMouseLeave={onMouseOut}
    >
      <Select
        {...{ ["aria-label"]: ariaLabel ?? label ?? "" }}
        classes={classes}
        closeMenuOnSelect={closeMenuOnSelect}
        components={{
          Control,
          DropdownIndicator,
          IndicatorSeparator: () => null,
          Menu,
          MultiValue: MultiValueComp,
          NoOptionsMessage,
          Option,
          SingleValue: SingleValueComp,
          ValueContainer,
          Placeholder,
          ...(light && !withLightDecorators
            ? { IndicatorsContainer: () => null, Placeholder: () => null }
            : {}),
          ...(onlyOptionIsSelected ? { IndicatorsContainer: () => null } : {}),
        }}
        customCss={customCss}
        customNoResult={customNoResult}
        disableUnderline={disableUnderline}
        hasError={hasError}
        hideSelectedOptions={hideSelectedOptions}
        inputId={id}
        isClearable={!cannotClear}
        isDisabled={disabled}
        isLoading={isLoading}
        isMulti={multiple}
        isSearchable={isSearchable}
        light={light}
        menuPortalTarget={withPortal ? document.body : null}
        onChange={(newValue) => {
          if (newValue === null) {
            setLocalValue(null);
            onChange?.(null);
            return;
          }
          setLocalValue(newValue as SelectOption);
          onChange?.(multiple ? { value: newValue } : newValue);
          if (customNoResult) setSearchInputValue("");
        }}
        options={options?.length ? options : []}
        placeholder={selectPlaceholder}
        presentationChips={presentationChips}
        searchInputValue={searchInputValue}
        styles={{
          menuPortal: (styles) => ({ ...styles, zIndex: 1000000000000 }),
          clearIndicator: (styles) => ({
            ...styles,
            padding: dp(2),
            borderRadius: dp(24),
            ":hover": iconHoverBorderStyle(theme),
          }),
        }}
        textFieldProps={textFieldProps}
        useShortValue={useShortValue}
        value={localValue}
        width={width}
        withChipEllipsis={withChipEllipsis}
      />
    </div>
  );
}

export const SelectConnectedWithForm = FormElement()<
  FormElementProps & SelectType
>(ReactSelect);

export default ReactSelect;
