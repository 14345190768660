import { MAX_REQUESTS, REQUEST_FORM_FIELDS } from "@recare/core/consts";
import {
  TransitionalCareImportedAuctionRequest,
  TransitionalCareRequest,
} from "@recare/core/types";
import Translations from "@recare/translations/types";
import {
  FormField,
  RequestListContextState,
  defaultRequestListContext,
} from ".";
import {
  getDefaultAddingState,
  getLastId,
  toFormRequest,
  validateFields,
} from "./utils";

export const ACTION_TYPES = {
  CLOSE_FORM: "CLOSE_FORM",
  OPEN_EDIT: "OPEN_EDIT",
  OPEN_ADD: "OPEN_ADD",
  SAVE: "SAVE",
  SET_FIELD: "SET_FIELD",
  DELETE: "DELETE",
  RESET: "RESET",
  IMPORT_REQUESTS: "IMPORT_REQUESTS",
} as const;

export type Actions =
  | {
      payload: {
        requests?: TransitionalCareImportedAuctionRequest[];
      };
      type: typeof ACTION_TYPES.IMPORT_REQUESTS;
    }
  | {
      payload: {
        requests: TransitionalCareRequest[];
      };
      type: typeof ACTION_TYPES.RESET;
    }
  | {
      type: typeof ACTION_TYPES.SAVE;
    }
  | {
      payload: {
        id: number;
      };
      type: typeof ACTION_TYPES.DELETE;
    }
  | {
      type: typeof ACTION_TYPES.OPEN_ADD;
    }
  | {
      payload: {
        request: TransitionalCareRequest;
      };
      type: typeof ACTION_TYPES.OPEN_EDIT;
    }
  | {
      type: typeof ACTION_TYPES.CLOSE_FORM;
    }
  | {
      payload:
        | {
            field: Exclude<
              FormField,
              typeof REQUEST_FORM_FIELDS.REQUEST_SENT_DATE
            >;
            value: string;
          }
        | {
            field: Extract<
              FormField,
              | typeof REQUEST_FORM_FIELDS.REQUEST_SENT_DATE
              | typeof REQUEST_FORM_FIELDS.REQUEST_SENT_TIME
              | typeof REQUEST_FORM_FIELDS.REQUEST_STATUS
            >;
            value: number | null;
          };
      type: typeof ACTION_TYPES.SET_FIELD;
    };

export const getReducer =
  (
    handleSubmit: (value: TransitionalCareRequest[]) => void,
    translations: Translations,
  ) =>
  (
    state: RequestListContextState,
    action: Actions,
  ): RequestListContextState => {
    switch (action.type) {
      case ACTION_TYPES.IMPORT_REQUESTS: {
        if (!action.payload.requests?.length) {
          return state;
        }

        const manualRequests = state.request_list.filter((r) => r.manual);

        const startId = getLastId(manualRequests);

        // to ensure a maximum of MAX_REQUESTS requests are entered into the list
        const sliced = action.payload.requests.slice(
          0,
          MAX_REQUESTS - manualRequests.length,
        );

        const newRequests: TransitionalCareRequest[] = sliced.map(
          toFormRequest(startId),
        );

        const requestList = [...manualRequests, ...newRequests];

        handleSubmit(requestList);

        return {
          ...state,
          request_list: requestList,
          validation: defaultRequestListContext.state.validation,
          adding: null,
          editing: null,
        };
      }
      case ACTION_TYPES.RESET: {
        return {
          ...state,
          request_list: action.payload.requests,
        };
      }
      case ACTION_TYPES.CLOSE_FORM: {
        return {
          ...state,
          validation: defaultRequestListContext.state.validation,
          adding: null,
          editing: null,
        };
      }
      case ACTION_TYPES.OPEN_ADD: {
        if (state.request_list.length >= MAX_REQUESTS) {
          return state;
        }

        const id = getLastId(state.request_list) + 1;

        return {
          ...state,
          validation: defaultRequestListContext.state.validation,
          adding: getDefaultAddingState(id),
          editing: null,
        };
      }
      case ACTION_TYPES.OPEN_EDIT: {
        return {
          ...state,
          validation: defaultRequestListContext.state.validation,
          editing: { ...action.payload.request, edited: true },
          adding: null,
        };
      }
      case ACTION_TYPES.SET_FIELD: {
        const updating = state.adding?.id ? "adding" : "editing";

        return {
          ...state,
          validation: {
            ...state.validation,
            [action.payload.field]: true,
          },
          [updating]: {
            ...(state[updating] as TransitionalCareRequest),
            [action.payload.field]: action.payload.value,
          },
        };
      }
      case ACTION_TYPES.SAVE: {
        const newRequest = state.adding ?? state.editing;

        if (!newRequest) return state;

        const [hasError, validationMap] = validateFields(
          newRequest,
          translations,
        );

        if (hasError) {
          return {
            ...state,
            validation: validationMap,
          };
        }

        const isEdit = !!state.editing;

        let list = [...state.request_list, newRequest];

        if (isEdit) {
          list = state.request_list.map((r) =>
            r.id === newRequest.id ? newRequest : r,
          );
        }

        handleSubmit(list);

        return {
          ...state,
          request_list: list,
          validation: defaultRequestListContext.state.validation,
          adding: null,
          editing: null,
        };
      }
      case ACTION_TYPES.DELETE: {
        const newRequestList = state.request_list.filter(
          (r) => r.id !== action.payload.id,
        );

        handleSubmit(newRequestList);

        return {
          ...state,
          request_list: newRequestList,
          validation: defaultRequestListContext.state.validation,
          adding: null,
          editing: null,
        };
      }
      default:
        return state;
    }
  };
