import Typography from "@mui/material/Typography";
import { SingleValueProps } from "react-select";
import { StyledChip } from "./MultiValue";
import { SelectOption } from "./types";

export function SingleValue({
  children,
  innerProps,
  isFocused,
  selectProps: { classes, customCss, light, presentationChips },
}: SingleValueProps<SelectOption> & { isFocused?: boolean }) {
  if (presentationChips) {
    return (
      <StyledChip
        isFocused={isFocused}
        tabIndex={-1}
        label={children}
        style={customCss?.chip || {}}
      />
    );
  }
  return (
    <Typography
      className={light ? classes.whiteSingleValue : classes.singleValue}
      {...(innerProps as any)}
    >
      {children}
    </Typography>
  );
}
