import LockIcon from "@mui/icons-material/Lock";
import Tooltip from "ds/components/Tooltip";
import { ICON_LIGHT_GREY } from "ds/materials/colors";
import React from "react";

export function LockSecurePin({
  style,
  tooltip,
}: {
  style?: React.CSSProperties;
  tooltip?: string;
}) {
  const Pin = <LockIcon htmlColor={ICON_LIGHT_GREY} style={style} />;

  return (
    <Tooltip title={tooltip} tooltipTextAlign="center">
      {Pin}
    </Tooltip>
  );
}
