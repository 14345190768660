import { emphasize, Theme } from "@mui/material/styles";
import { TextFieldProps } from "@mui/material/TextField";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ERROR_COLOR, WHITE } from "ds/materials/colors";
import { border, dp, padding } from "ds/materials/metrics";
import { FONT_SIZE_14 } from "ds/materials/typography";
import { SelectType } from "./types";

export const useStyles = ({
  fontSize = FONT_SIZE_14,
  isSearchable,
  withChipEllipsis,
  withLightDecorators,
}: {
  fontSize?: string;
  isSearchable: boolean | undefined;
  withChipEllipsis?: number | undefined;
  withLightDecorators?: boolean | undefined;
}) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        marginTop: 0,
      },
      whiteMaterialInput: {
        "& input": {
          color: "white !important",
        },
        "&:before": {
          borderBottomColor: "white",
        },
        "&:hover:before": {
          borderBottomColor: "white !important",
        },
      },
      input: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "auto",
        padding: 0,
        cursor: isSearchable ? "text" : "pointer",
        transform: withLightDecorators
          ? `translate(${dp(4)},${dp(-5)})`
          : `translate(${dp(4)},${dp(0)})`,
      },
      chip: {
        margin: theme.spacing(1 / 2, 1 / 4),
        maxWidth: withChipEllipsis ? dp(withChipEllipsis) : undefined,
        whiteSpace: withChipEllipsis ? "nowrap" : undefined,
        overflow: withChipEllipsis ? "hidden" : undefined,
        textOverflow: withChipEllipsis ? "ellipsis" : undefined,
      },
      chipFocused: {
        backgroundColor: emphasize(
          theme.palette.mode === "light"
            ? theme.palette.grey[300]
            : theme.palette.grey[600],
          0.08,
        ),
      },
      noOptionsMessage: {
        padding: theme.spacing(1, 2),
      },
      inputError: {
        borderBottom: border({ width: 2, color: ERROR_COLOR }),
      },
      singleValue: {
        fontSize,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        position: "absolute",
      },
      whiteSingleValue: {
        fontSize,
        color: WHITE,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        position: "absolute",
      },
      paper: {
        position: "relative",
        overflow: "initial",
        zIndex: 10000,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
      },
      divider: {
        height: theme.spacing(2),
      },
      valueContainerSingle: {
        display: "flex",
        alignItems: "center",
        maxWidth: "calc(100% - 40px)",
        cursor: "pointer",
        padding: padding(1),
      },
      valueContainerMulti: {
        display: "inline-flex",
        flexWrap: "wrap",
        alignItems: "center",
        width: "calc(100% - 30px)",
      },
      valueContainerMultiEllipsis: {
        fontSize,
        overflow: "hidden",
        whiteSpace: "nowrap",
        display: "inline-block",
        textOverflow: "ellipsis",
      },
      dropdownIndicatorContainer: {
        padding: padding(0.5, 1),
        cursor: "pointer",
      },
      dropdownIndicatorContainerFilter: {
        position: "relative",
        top: "-4px",
      },
      menuItem: {
        display: "block",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "100%",
        padding: padding(1, 2),
        outlineOffset: dp(-2),
        "&.Mui-selected": {
          outline: `${dp(1)} solid ${theme.palette.primary.dark}`,
        },
      },
    }),
  );

export function isValueEmpty(selectValue: any, multiple: boolean) {
  if (!selectValue) return true;

  // is multi select
  if (multiple) {
    return (
      (Array.isArray(selectValue) && selectValue.length === 0) ||
      (Array.isArray(selectValue.value) && selectValue.value.length === 0)
    );
  }

  // is single select
  if (selectValue instanceof Object && !selectValue.label) {
    return true;
  }
  return typeof selectValue === "string" && selectValue.length === 0;
}

export function getTextFieldStyles({
  hasError,
  isShrink,
}: {
  hasError?: boolean;
  isShrink?: boolean;
}) {
  let textFieldStyles = {
    fontSize: FONT_SIZE_14,
    padding: padding(1),
    transform: !isShrink
      ? `translate(0, ${dp(28)}) scale(1)`
      : `translate(0, ${dp(4)}) scale(0.8)`,
  };

  if (hasError) {
    textFieldStyles = Object.assign(textFieldStyles, { color: ERROR_COLOR });
  }
  return textFieldStyles;
}

export function getTextFieldProps({
  customNoResult,
  elementName,
  empty,
  floatingLabel,
  isShrink,
  label,
  onBlur,
  onFocus,
  onSearch,
  placeholder,
  required,
  setIsShrink,
  setSearchInputValue,
  testId,
  textFieldStyles,
}: Partial<SelectType> & {
  empty: boolean;
  isShrink?: boolean;
  setIsShrink: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
  textFieldStyles: ReturnType<typeof getTextFieldStyles>;
}) {
  const handleFocus: TextFieldProps["onFocus"] = (e) => {
    if (floatingLabel && empty && placeholder) {
      setIsShrink(true);
    }
    onFocus?.(e);
  };
  const handleBlur: TextFieldProps["onBlur"] = (e) => {
    if (floatingLabel && empty) {
      setIsShrink(false);
    }
    onBlur?.(e);
  };
  const handleChange: TextFieldProps["onChange"] = (e) => {
    if (customNoResult) {
      setSearchInputValue(e.target?.value);
    }
    onSearch?.(e);
  };

  return {
    label: label ? `${label}${required ? " *" : ""}` : "",
    InputLabelProps: {
      shrink: isShrink,
      style: textFieldStyles,
    },
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    placeholder,
    "data-testid": testId ?? elementName,
  };
}
