import Translations from "@recare/translations/types";

const FILES_PAGE_KEY = "files_page" as const;
export const FILES_AVAILABLE_TO_SHARE_KEY = "available_file_to_share" as const;

const FILES_PAGE_STEPS = {
  FILES_TAB: "files_tab",
  SELECT_FILES: "select_files",
  SHOW_MORE_INFO: "show_more_info",
  DOCUMENTATION_TAB: "documentation_tab",
  SEARCH_TAB: "search_tab",
} as const;

const FILES_PAGE_HIS_STEPS = {
  FILE_TABLE_MENU_ACTION: "file_table_action_menu",
} as const;

export const FILES_PAGE = {
  key: FILES_PAGE_KEY,
  steps: {
    [FILES_PAGE_STEPS.FILES_TAB]: {
      key: FILES_PAGE_STEPS.FILES_TAB,
      intro: (t: Translations) => t.tours.files.stepA.intro,
      title: (t: Translations) => t.tours.files.stepA.title,
      step: 1,
    },
    [FILES_PAGE_STEPS.SELECT_FILES]: {
      key: FILES_PAGE_STEPS.SELECT_FILES,
      intro: (t: Translations) => t.tours.files.stepB.intro,
      title: (t: Translations) => t.tours.files.stepB.title,
      step: 2,
    },
    [FILES_PAGE_STEPS.SHOW_MORE_INFO]: {
      key: FILES_PAGE_STEPS.SHOW_MORE_INFO,
      intro: (t: Translations) => t.tours.files.stepC.intro,
      title: (t: Translations) => t.tours.files.stepC.title,
      step: 3,
    },
    [FILES_PAGE_STEPS.DOCUMENTATION_TAB]: {
      key: FILES_PAGE_STEPS.DOCUMENTATION_TAB,
      intro: (t: Translations) => t.tours.files.stepD.intro,
      title: (t: Translations) => t.tours.files.stepD.title,
      step: 4,
    },
    [FILES_PAGE_STEPS.SEARCH_TAB]: {
      key: FILES_PAGE_STEPS.SEARCH_TAB,
      intro: (t: Translations) => t.tours.files.stepE.intro,
      title: (t: Translations) => t.tours.files.stepE.title,
      step: 5,
    },
  },
} as const;

export const FILES_AVAILABLE_TO_SHARE = {
  key: FILES_AVAILABLE_TO_SHARE_KEY,
  steps: {
    [FILES_PAGE_HIS_STEPS.FILE_TABLE_MENU_ACTION]: {
      key: FILES_PAGE_HIS_STEPS.FILE_TABLE_MENU_ACTION,
      intro: (t: Translations) => t.tours.availableFilesToShare.stepA.intro,
      title: (t: Translations) => t.tours.availableFilesToShare.stepA.title,
      step: 1,
    },
  },
} as const;
