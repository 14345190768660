import Translations from "@recare/translations/types";

const CAREPROVIDER_DASHBOARD_PAGE_KEY = "careprovider_dashboard" as const;

const CAREPROVIDER_DASHBOARD_STEPS = {
  BULK_DECLINE: "bulk_decline_checkbox",
  REVERSE_SEARCH_NAVBAR: "reverse_search_navbar",
  OTHER_TAB: "other_tab",
  BLOCK_CAPACITIES: "capacity_setup_link",
} as const;

export const CAREPROVIDER_DASHBOARD_PAGE = {
  key: CAREPROVIDER_DASHBOARD_PAGE_KEY,
  steps: {
    [CAREPROVIDER_DASHBOARD_STEPS.BULK_DECLINE]: {
      key: CAREPROVIDER_DASHBOARD_STEPS.BULK_DECLINE,
      title: (t: Translations) => t.tours.receiverDashboard.stepB.title,
      intro: (t: Translations) => t.tours.receiverDashboard.stepB.intro,
      step: 1,
    },
    [CAREPROVIDER_DASHBOARD_STEPS.OTHER_TAB]: {
      key: CAREPROVIDER_DASHBOARD_STEPS.OTHER_TAB,
      title: (t: Translations) => t.tours.receiverDashboard.stepC.title,
      intro: (t: Translations) => t.tours.receiverDashboard.stepC.intro,
      step: 2,
    },
    [CAREPROVIDER_DASHBOARD_STEPS.REVERSE_SEARCH_NAVBAR]: {
      key: CAREPROVIDER_DASHBOARD_STEPS.REVERSE_SEARCH_NAVBAR,
      title: (t: Translations) => t.tours.receiverDashboard.stepThree.title,
      intro: (t: Translations) => t.tours.receiverDashboard.stepThree.intro,
      step: 3,
    },
    [CAREPROVIDER_DASHBOARD_STEPS.BLOCK_CAPACITIES]: {
      key: CAREPROVIDER_DASHBOARD_STEPS.BLOCK_CAPACITIES,
      title: (t: Translations) => t.tours.receiverDashboard.stepD.title,
      intro: (t: Translations) => t.tours.receiverDashboard.stepD.intro,
      step: 4,
    },
  },
  nextLabel: (t: Translations) => t.tours.reverseSearch.nextButton,
  prevLabel: (t: Translations) => t.tours.reverseSearch.backButton,
  doneLabel: (t: Translations) => t.tours.reverseSearch.doneButton,
  exitOnOverlayClick: false,
  scrollToElement: false,
} as const;
