import { Auction, Careseeker } from "@recare/core/types";

export const composeOr =
  (
    ...functions: Array<
      (props: { careseeker?: Careseeker; formInputValue: Auction }) => boolean
    >
  ) =>
  (props: { careseeker?: Careseeker; formInputValue: Auction }) =>
    functions.some((f) => f(props));

export const composeAnd =
  (
    ...functions: Array<
      (props: { careseeker?: Careseeker; formInputValue: Auction }) => boolean
    >
  ) =>
  (props: { careseeker?: Careseeker; formInputValue: Auction }) =>
    functions.every((f) => f(props));

export const blacklist =
  (key: string) =>
  ({ careseeker }: { careseeker?: Careseeker }) => {
    if (
      !careseeker?.config?.blacklisted_assessment_fields
    )
      return true;
    return !careseeker.config.blacklisted_assessment_fields.includes(key);
  };
