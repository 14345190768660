import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { IconButton, SvgIcon } from "@mui/material";
import { REQUEST_FORM_FIELDS } from "@recare/core/consts";
import { useDateLocale } from "@recare/core/hooks/useDateLocale";
import { formatAddress } from "@recare/core/model/utils/location";
import { ONTOLOGY_TRANSITIONAL_CARE_REQUEST_STATUS } from "@recare/core/model/utils/ontologies";
import { useGetOntology } from "@recare/core/model/utils/ontologies/hooks";
import { TransitionalCareRequest } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import { useA11yMessage } from "containers/A11yStatusProvider/A11yStatusContext";
import { format, fromUnixTime } from "date-fns";
import { MEDIUM_GREY, WHITE } from "ds/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds/materials/layouts";
import { margin, padding, sizing } from "ds/materials/metrics";
import {
  Body,
  FONT_SIZE_16,
  FONT_WEIGHT_SEMI_BOLD,
} from "ds/materials/typography";
import { getSectionIdLabel } from ".";
import { useRequestListContext } from "../RequestListContext";
import { ACTION_TYPES } from "../RequestListContext/reducer";

export const CardIconButton = ({
  ariaLabel,
  Icon,
  onClick,
  testId,
}: {
  Icon: typeof SvgIcon;
  ariaLabel?: string;
  onClick: () => void;
  testId?: string;
}) => {
  return (
    <IconButton
      data-testid={testId}
      href=""
      aria-label={ariaLabel}
      onClick={onClick}
      size="small"
      sx={{ height: "fit-content" }}
    >
      <Icon sx={{ fontSize: FONT_SIZE_16 }} />
    </IconButton>
  );
};

export const RequestCard = ({
  id,
  request,
}: {
  id: number;
  request: TransitionalCareRequest;
}) => {
  const { currentLocale } = useDateLocale();
  const translations = useTranslations();
  const { dispatch } = useRequestListContext();
  const getOntology = useGetOntology();
  const { updateA11yMessage } = useA11yMessage();

  return (
    <HorizontalLayout
      data-testid={`request-card-${id}`}
      width="100%"
      boxSizing="border-box"
      minHeight={sizing(12)}
      backgroundColor={WHITE}
      padding={padding(1, 2)}
      overflow="visible"
    >
      <VerticalLayout boxSizing="border-box" width="3%">
        <Body
          data-testid={`sectionid-${id}`}
          fontWeight={FONT_WEIGHT_SEMI_BOLD}
          margin="0"
        >
          {getSectionIdLabel(id)}
        </Body>
      </VerticalLayout>
      <VerticalLayout width="75%" justify="space-between">
        <Body
          data-testid="careprovider-name"
          fontWeight={FONT_WEIGHT_SEMI_BOLD}
          margin="0"
        >
          {request[REQUEST_FORM_FIELDS.CAREPROVIDER_NAME]}
        </Body>
        <Body
          data-testid="careprovider-address"
          fontWeight={FONT_WEIGHT_SEMI_BOLD}
          margin="0"
        >
          {formatAddress({
            address: request[REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_ADDRESS],
            zip_code: request[REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_ZIPCODE],
            city: request[REQUEST_FORM_FIELDS.CAREPROVIDER_ADDRESS_CITY],
          })}
        </Body>
        <HorizontalLayout>
          <Body
            data-testid="sent-date"
            fontWeight={FONT_WEIGHT_SEMI_BOLD}
            margin="0"
          >
            {format(
              fromUnixTime(request[REQUEST_FORM_FIELDS.REQUEST_SENT_DATE] ?? 0),
              "P",
              {
                locale: currentLocale,
              },
            )}
          </Body>
          <Body
            data-testid="sent-time"
            fontWeight={FONT_WEIGHT_SEMI_BOLD}
            margin={margin(0, 0, 0, 1)}
          >
            {format(
              fromUnixTime(request[REQUEST_FORM_FIELDS.REQUEST_SENT_TIME] ?? 0),
              "HH:mm",
              {
                locale: currentLocale,
              },
            )}
          </Body>
          <Body fontWeight={FONT_WEIGHT_SEMI_BOLD} margin={margin(0, 0, 0, 1)}>
            -
          </Body>
          <Body
            data-testid="request-status"
            fontWeight={FONT_WEIGHT_SEMI_BOLD}
            margin={margin(0, 0, 0, 1)}
          >
            {getOntology({
              type: ONTOLOGY_TRANSITIONAL_CARE_REQUEST_STATUS,
              key: request[REQUEST_FORM_FIELDS.REQUEST_STATUS],
            })}
          </Body>
        </HorizontalLayout>
      </VerticalLayout>
      <HorizontalLayout overflow="visible" width="15%" justify="space-between">
        {request.manual && (
          <Body data-testid="manually-added" color={MEDIUM_GREY} margin="0">
            {
              translations.patientForms.transitionalCareForm.sectionE
                .manuallyAddedProvider
            }
          </Body>
        )}
      </HorizontalLayout>
      <HorizontalLayout overflow="visible" width="7%" justify="space-between">
        <CardIconButton
          testId="edit-button"
          ariaLabel={
            translations.patientForms.transitionalCareForm.sectionE
              .editButtonAriaLabel
          }
          onClick={() =>
            dispatch({
              type: ACTION_TYPES.OPEN_EDIT,
              payload: { request },
            })
          }
          Icon={ModeEditOutlineOutlinedIcon}
        />
        <CardIconButton
          testId="delete-button"
          ariaLabel={
            translations.patientForms.transitionalCareForm.sectionE
              .deleteButtonAria
          }
          onClick={() => {
            dispatch({
              type: ACTION_TYPES.DELETE,
              payload: { id: request.id },
            });
            updateA11yMessage(
              translations.patientForms.transitionalCareForm.sectionE.deletionScreenReaderAnnouncement(
                {
                  providerName: request[REQUEST_FORM_FIELDS.CAREPROVIDER_NAME],
                },
              ),
            );
          }}
          Icon={DeleteOutlineOutlinedIcon}
        />
      </HorizontalLayout>
    </HorizontalLayout>
  );
};
