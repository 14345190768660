import Translations from "@recare/translations/types";

export const SEARCH_MERGE_AUCTION_NOT_STARTED_KEY =
  "search_merge_not_started" as const;

const SEARCH_MERGE_AUCTION_NOT_STARTED_STEPS = {
  SEARCH_TAB: "search_tab",
  CONTACT_PROVIDERS: "contact_providers",
  PATIENT_WISH: "patient_wish",
  TABLE_ROW: "table_row",
} as const;

export const SEARCH_MERGE_AUCTION_NOT_STARTED = {
  key: SEARCH_MERGE_AUCTION_NOT_STARTED_KEY,
  steps: {
    [SEARCH_MERGE_AUCTION_NOT_STARTED_STEPS.SEARCH_TAB]: {
      key: SEARCH_MERGE_AUCTION_NOT_STARTED_STEPS.SEARCH_TAB,
      intro: (t: Translations) => t.tours.searchMerge.stepA.intro,

      title: (t: Translations) => t.tours.searchMerge.stepA.title,
      step: 1,
    },
    [SEARCH_MERGE_AUCTION_NOT_STARTED_STEPS.CONTACT_PROVIDERS]: {
      key: SEARCH_MERGE_AUCTION_NOT_STARTED_STEPS.CONTACT_PROVIDERS,
      intro: (t: Translations) => t.tours.searchMerge.stepB.intro,
      title: (t: Translations) => t.tours.searchMerge.stepB.title,
      step: 2,
    },
    [SEARCH_MERGE_AUCTION_NOT_STARTED_STEPS.PATIENT_WISH]: {
      key: SEARCH_MERGE_AUCTION_NOT_STARTED_STEPS.PATIENT_WISH,
      intro: (t: Translations) => t.tours.searchMerge.stepC.intro,
      title: (t: Translations) => t.tours.searchMerge.stepC.title,
      step: 3,
    },
    [SEARCH_MERGE_AUCTION_NOT_STARTED_STEPS.TABLE_ROW]: {
      key: SEARCH_MERGE_AUCTION_NOT_STARTED_STEPS.TABLE_ROW,
      intro: (t: Translations) => t.tours.searchMerge.stepD.intro,
      title: (t: Translations) => t.tours.searchMerge.stepD.title,
      step: 4,
    },
  },
  nextLabel: (t: Translations) => t.tours.reverseSearch.nextButton,
  prevLabel: (t: Translations) => t.tours.reverseSearch.backButton,
  doneLabel: (t: Translations) => t.tours.reverseSearch.doneButton,
  exitOnOverlayClick: false,
  disablePageScroll: true,
} as const;
