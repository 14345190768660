import { padding } from "ds/materials/metrics";
import { MenuItem } from "dsl/atoms/MenuItem";
import { OptionProps } from "react-select";
import { SelectOption } from "./types";

export function Option({
  children,
  innerProps,
  isFocused,
  isSelected,
  selectProps: { classes },
}: OptionProps<SelectOption>) {
  return (
    <MenuItem
      selected={isFocused}
      className={classes.menuItem}
      component="div"
      sx={{
        padding: padding(1, 2),
        fontWeight: isSelected ? 500 : 400,
      }}
      {...(innerProps as any)}
      role="option"
    >
      <>{children}</>
    </MenuItem>
  );
}
