import { ValueContainerProps } from "react-select";
import { SelectOption } from "./types";

export function ValueContainer({
  children,
  isMulti,
  selectProps: { classes },
}: ValueContainerProps<SelectOption>) {
  return (
    <div
      className={
        !isMulti ? classes.valueContainerSingle : classes.valueContainerMulti
      }
    >
      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        {children}
      </div>
    </div>
  );
}
