import { FormLabel, FormLabelProps } from "@mui/material";
import { BLACK } from "ds/materials/colors";
import { LINE_HEIGHT_28 } from "ds/materials/typography";
import { PropsWithChildren } from "react";

export function SubheadingFormLabel({
  children,
  ...props
}: PropsWithChildren<Omit<FormLabelProps, "focused">>) {
  return (
    <FormLabel
      component="legend"
      {...props}
      // this only prevents applying the Mui-focused class (if inside group, change label to primary color)
      // https://mui.com/material-ui/api/form-label/
      focused={false}
      sx={{
        color: props.error ? undefined : BLACK,
        lineHeight: LINE_HEIGHT_28,
        ...props.sx,
      }}
    >
      {children}
    </FormLabel>
  );
}
