import ArrowDown from "@mui/icons-material/ArrowDropDown";
import ArrowUp from "@mui/icons-material/ArrowDropUp";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import classNames from "classnames";
import { iconHoverBorderStyle } from "ds/materials/layouts";
import { dp } from "ds/materials/metrics";
import { DropdownIndicatorProps } from "react-select";
import { SelectOption } from "./types";

function getIconStyle({
  isDisabled,
  theme,
}: {
  isDisabled?: boolean;
  theme: Theme;
}) {
  return {
    width: dp(24),
    height: dp(24),
    opacity: isDisabled ? 0.25 : 0.8,
    borderRadius: dp(24),
    ":hover": iconHoverBorderStyle(theme),
  };
}

export function DropdownIndicator({
  isDisabled,
  selectProps: { classes, light, menuIsOpen },
}: DropdownIndicatorProps<SelectOption>) {
  const theme = useTheme();
  const iconStyle = getIconStyle({ isDisabled, theme });
  return (
    <div className={classNames(classes.dropdownIndicatorContainer)}>
      {menuIsOpen ? (
        <ArrowUp htmlColor={light ? "white" : "currentColor"} sx={iconStyle} />
      ) : (
        <ArrowDown
          htmlColor={light ? "white" : "currentColor"}
          sx={{ ...iconStyle, opacity: 0.5 }}
        />
      )}
    </div>
  );
}
