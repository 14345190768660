import Typography from "@mui/material/Typography";
import { useTranslations } from "@recare/translations";
import { NoticeProps } from "react-select";
import { SelectOption } from "./types";

export function NoOptionsMessage({
  innerProps,
  selectProps,
}: NoticeProps<SelectOption>) {
  const translations = useTranslations();

  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...(innerProps as any)}
    >
      {translations.general.noResult}
    </Typography>
  );
}
